import React, { Component } from 'react';
import _ from 'lodash';

import Block from 'components/Blocks';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`;

const Inner = styled.div`
  max-width:100rem;
  ${media.lessThan("tablet")`
    width:100%;
    max-width:100%;
  `}
`;

class Welcome extends Component {
  render() {
    const { blocks, shopify } = this.props;

    return (
      <Container>
        <Inner>
          <Block data={ _.find(blocks, (b) => b.blockId === 'SupportWelcome' ) } shopify={shopify} />
          <Block data={ _.find(blocks, (b) => b.blockId === 'SupportForm' ) } shopify={shopify} />
        </Inner>
      </Container>
    )
  }
}

export default Welcome