import React, { Component } from "react";
import { graphql } from 'gatsby'

import Layout from 'components/Page/Support/Layout';
import Nav from 'components/Page/Support/Nav';
import Welcome from 'components/Page/Support/Welcome';

class Support extends Component {
  
    render() {
      const categories = this.props.pageContext.categories
      const blocks = this.props.data.allContentfulBlock.nodes
      const shopify = this.props.pageContext.shopify

      return ( 
        <Layout>
          <Nav categories={ categories } />
          
          <Welcome blocks={ blocks } shopify={ shopify } />
        </Layout>
      )
    }
}

export default Support

// language=GraphQL
export const pageQuery = graphql`
  query SupportBlocksQuery($blocks: [String]!) {
    allContentfulBlock(filter: {blockId: {in: $blocks}}) {
      nodes {
        ...BlockFields
      }
    }
  }
`;